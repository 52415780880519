import React, { useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import isNil from 'ramda/src/isNil';
import { dynamicString } from 'utils/string';

import Tooltip from 'components/Tooltip/Tooltip';
import {
  ActionButton,
  ActionSpan,
  DeleteButtonIcon,
  LockButtonIcon,
} from 'components/Table/TableStyled';
import { AccessibleText } from 'styled/Global';
import styled from 'styled-components/macro';
import { TABLE_COPY } from 'components/Table/constants';
import { ACCOMMODATION_SCHEDULE_COPY } from 'pages/Details/Building/AccommodationSchedule/constants';
import {
  checkIfFloorCanBeRemoved,
  checkIfLevelExists,
  shouldShowTitle,
} from 'pages/Details/Building/AccommodationSchedule/helpers';
import { USE_TYPES } from 'pages/Details/Company/constants';
import { getBuildingType } from 'store/selectors/BuildingSelectors/buildingSelectors';
import useMultiline from '../hooks/useMultiline';
import { LineConfigProps, OptionProps, OptionSets } from '../types';

import BatchControlOrdered from './BatchControlOrdered/BatchControlOrdered';
import {
  StyledAddPartitionButton,
  StyledAddIcon,
  MultiLineStyled,
  FloorPartitionMultiLineWrapper,
  FieldColumns,
  StyledButtonDeleteWrapper,
  DeletePartitionContainer,
  StyledButtonWrapper,
  MultiLineHeader,
  StyledAddLineButton,
  RowWrapper,
} from './FormMultilineStyled';
import { FORM_BUILDER_COPY, OPTION_SETS } from '../constants';
import FormField from '../FormField/FormField';
import { MultiLineWithBatchOrderedProps } from './types';
import { FIELDS, LINE_PARAMS } from './constants';

export const FloorLimitReachedWarning = styled.span`
  color: red;
  padding: 20px 0;
  display: flex;
  justify-content: center;
`;

const getDeleteFloorButton = (
  i: number,
  line: any,
  handleDeleteRow: (i: number) => void,
) => {
  if (line.floorLevel?.value === 0) {
    return null;
  }
  if (!line.canBeRemoved) {
    return (
      <StyledButtonDeleteWrapper>
        <Tooltip
          tooltipId={`rowLocked_${i}`}
          button={
            <ActionSpan>
              <LockButtonIcon />
              <AccessibleText>{TABLE_COPY.actions.lock}</AccessibleText>
            </ActionSpan>
          }
        >
          {ACCOMMODATION_SCHEDULE_COPY.tooltip}
        </Tooltip>
      </StyledButtonDeleteWrapper>
    );
  }

  return (
    <StyledButtonDeleteWrapper>
      <ActionButton type="button" onClick={() => handleDeleteRow(i)}>
        <DeleteButtonIcon />
        <AccessibleText>{TABLE_COPY.actions.delete}</AccessibleText>
      </ActionButton>
    </StyledButtonDeleteWrapper>
  );
};

const getDeletePartitionButton = (
  i: number,
  line: any,
  handleDeletePartition: (i: number, partitionIndex: number) => void,
  partitionIndex: number,
) => {
  if (line.hasLeases?.value) {
    return (
      <StyledButtonWrapper>
        <DeletePartitionContainer>
          <Tooltip
            tooltipId={`rowLocked_partition_${i}`}
            button={
              <ActionSpan>
                <LockButtonIcon />
                <AccessibleText>{TABLE_COPY.actions.lock}</AccessibleText>
              </ActionSpan>
            }
          >
            {ACCOMMODATION_SCHEDULE_COPY.tooltip}
          </Tooltip>
        </DeletePartitionContainer>
      </StyledButtonWrapper>
    );
  }

  return (
    <StyledButtonWrapper>
      <DeletePartitionContainer>
        <ActionButton
          type="button"
          onClick={() => handleDeletePartition(i, partitionIndex)}
        >
          <DeleteButtonIcon />
          <AccessibleText>{TABLE_COPY.actions.delete}</AccessibleText>
        </ActionButton>
      </DeletePartitionContainer>
    </StyledButtonWrapper>
  );
};

const FloorPartitionMultiline = ({
  lineConfig,
  handleOnChange,
  addButton,
  addBatchLabel,
  formStateItems,
  multilineTitle,
  groupId,
  columns,
}: MultiLineWithBatchOrderedProps) => {
  const {
    onFieldChange,
    addOrderedRows,
    handleDeleteRow,
    handleDeletePartition,
    rowFields,
  } = useMultiline(formStateItems, handleOnChange);

  const buildingType = useSelector(getBuildingType);

  const getOptions = (options?: OptionProps[], optionSet?: OptionSets) => {
    if (!options && !optionSet) return undefined;
    if (optionSet) return OPTION_SETS[optionSet] as OptionProps[];

    return options;
  };

  const getItemValue = (entity?: any) => {
    if (typeof entity === 'undefined') return '';

    if (entity && !isNil(entity.value)) return entity.value;

    return '';
  };

  const onInsertRows = (quantity: number, position: string) => {
    addOrderedRows(quantity, FIELDS.FLOOR_LEVEL, position);
  };

  const highestValidFloorNumber = 150;
  const lowestValidFloorNumber = -10;
  const currentHighestFloor = useMemo(
    () => Math.max(...rowFields.map((r: any) => r.floorLevel?.value)),
    [rowFields],
  );
  const currentLowestFloor = useMemo(
    () => Math.min(...rowFields.map((r: any) => r.floorLevel?.value)),
    [rowFields],
  );
  const canAddHigherFloor = currentHighestFloor < highestValidFloorNumber;
  const canAddLowerFloor = currentLowestFloor > lowestValidFloorNumber;

  console.log(currentHighestFloor);
  console.log(currentLowestFloor);

  const addPartition = useCallback(
    (i: number) => {
      const updatedPartitions: any = [...rowFields];

      if (!updatedPartitions[i].partitions) {
        updatedPartitions[i] = {
          partitions: {
            value: [{ index: 0 }],
          },
        };
      } else {
        updatedPartitions[i].partitions?.value.push({
          index: updatedPartitions[i].partitions.value.length,
        });
      }

      onFieldChange(
        i,
        updatedPartitions[i].partitions.value,
        FIELDS.PARTITIONS,
        'Partitions',
        'MultiLinePartition',
      );
    },
    [onFieldChange, rowFields],
  );

  const handleChildFieldChange = useCallback(
    (line: any, value: any, floorIndex: number, partitionIndex?: number) => {
      // update value for correct partition index
      let state;
      if (
        Object.prototype.hasOwnProperty.call(
          rowFields[floorIndex],
          FIELDS.PARTITIONS,
        )
      ) {
        state = rowFields[floorIndex].partitions.value.map(
          (partition: any, index: number) => {
            if (partitionIndex === index) {
              return { ...partition, [line.name]: { value } };
            }
            return partition;
          },
        );
      } else {
        state = [{ index: 0, [line.name]: { value } }];
      }

      onFieldChange(
        floorIndex,
        state,
        FIELDS.PARTITIONS,
        'Partitions',
        'MultiLinePartition',
      );
    },
    [onFieldChange, rowFields],
  );

  const checkIfDisabled = (
    line: any,
    itemValue: any,
    hasActiveLeases: boolean,
  ) => {
    const isGroundFloor =
      itemValue === ACCOMMODATION_SCHEDULE_COPY.ground ||
      itemValue === ACCOMMODATION_SCHEDULE_COPY.upper ||
      itemValue === ACCOMMODATION_SCHEDULE_COPY.lower;
    if (line.name === 'floorName' && isGroundFloor) {
      return true;
    }
    if (line.name === 'isHistoric' && hasActiveLeases) {
      return true;
    }

    return false;
  };
  const getFormField = useCallback(
    (
      line: any,
      itemValue: any,
      i: number,
      item: any,
      isChild = false,
      partitionIndex?: number,
      hasActiveLeases = false,
    ) => {
      const itemProps = { ...line, groupId: '', fieldType: undefined };
      let fieldKey = `${line.name}-${item?.floorId?.value || i}`;

      if (partitionIndex) fieldKey = fieldKey.concat(`-${partitionIndex}`);

      const isDisabled = checkIfDisabled(line, itemValue, hasActiveLeases);

      return (
        <FormField
          name={fieldKey}
          key={fieldKey}
          label={line.label}
          handleOnChange={
            isChild
              ? (value) =>
                  handleChildFieldChange(line, value, i, partitionIndex)
              : (value) => {
                  onFieldChange(
                    i,
                    value,
                    line.name,
                    line.label,
                    line.componentType,
                    line.resetFormState,
                    line.resetRefsOnChange,
                  );
                }
          }
          groupId={groupId}
          options={getOptions(line.options, line.optionSet)}
          type={line.componentType}
          value={itemValue}
          lineValues={item}
          validation={line.validation}
          rowFields={rowFields}
          isGroup
          itemProps={itemProps}
          readOnly={isDisabled}
        />
      );
    },
    [groupId, handleChildFieldChange, onFieldChange, rowFields],
  );

  const getChildren = useCallback(
    (line: any, itemValue: any, i: number, item: any) => {
      if (Array.isArray(itemValue)) {
        return itemValue.map((partition: any, index: number) => (
          // eslint-disable-next-line react/no-array-index-key
          <React.Fragment key={`line-${i}-${index}`}>
            {line.childFields?.map((child: any) => {
              return getFormField(
                child,
                getItemValue(partition[child.name]),
                i,
                item,
                true,
                index,
                partition?.hasActiveLeases?.value,
              );
            })}
            {getDeletePartitionButton(
              i,
              partition,
              handleDeletePartition,
              index,
            )}
          </React.Fragment>
        ));
      }
      return null;
    },
    [getFormField, handleDeletePartition],
  );

  const updatedFields = useMemo(() => checkIfFloorCanBeRemoved(rowFields), [
    rowFields,
  ]);

  const getLine = useCallback(
    (item: any, i: number) => {
      const getMultilineTitle = (title: number) => {
        return dynamicString(multilineTitle as string, [`${title}`]);
      };

      const originalIndex = updatedFields.findIndex(
        (row: any) => row.floorLevel.value === item.floorLevel.value,
      );
      return (
        <RowWrapper>
          {shouldShowTitle(updatedFields, item) && (
            <MultiLineHeader columns={columns}>
              {getMultilineTitle(rowFields[originalIndex].floorLevel.value)}
            </MultiLineHeader>
          )}
          <FloorPartitionMultiLineWrapper columns={columns} key={`line-${i}`}>
            {lineConfig.map((line: LineConfigProps, lineIndex: number) => {
              const itemValue = getItemValue(item[line.name]);
              return (
                // eslint-disable-next-line react/no-array-index-key
                <React.Fragment key={`${line.name}-${i}-${lineIndex}`}>
                  <FieldColumns
                    hasChildren={line.childFields}
                    key={`item${line.name}`}
                    columns={line.columns}
                  >
                    {getFormField(line, itemValue, i, item)}
                    {getChildren(line, itemValue, i, item)}
                  </FieldColumns>

                  {line.name === 'floorName' && (
                    <StyledAddPartitionButton
                      onClick={() => addPartition(i)}
                      type="button"
                    >
                      <StyledAddIcon />
                      {FORM_BUILDER_COPY.multiline.buttons.addPartition}
                    </StyledAddPartitionButton>
                  )}
                </React.Fragment>
              );
            })}

            {getDeleteFloorButton(originalIndex, item, handleDeleteRow)}
          </FloorPartitionMultiLineWrapper>
        </RowWrapper>
      );
    },
    [
      addPartition,
      columns,
      getChildren,
      getFormField,
      handleDeleteRow,
      lineConfig,
      rowFields,
      updatedFields,
      multilineTitle,
    ],
  );

  const hasLowerRow = checkIfLevelExists(updatedFields, -0.5);
  const hasUpperRow = checkIfLevelExists(updatedFields, 0.5);

  const groundFloors = updatedFields.filter(
    (floor: any) => floor.floorLevel.value > -1 && floor.floorLevel.value < 1,
  );

  const upperFloors = updatedFields.filter(
    (floor: any) => floor.floorLevel.value > 0.5,
  );
  const lowerFloors = updatedFields.filter(
    (floor: any) => floor.floorLevel.value < -0.5,
  );

  return (
    <div>
      {canAddHigherFloor ? (
        <StyledAddLineButton
          type="button"
          onClick={() => onInsertRows(1, LINE_PARAMS.TOP)}
        >
          <StyledAddIcon />
          {buildingType === USE_TYPES.OFFICE
            ? FORM_BUILDER_COPY.multiline.buttons.addLineAbove
            : FORM_BUILDER_COPY.multiline.buttons.addLineAboveRetailIndustrial}
        </StyledAddLineButton>
      ) : (
        <FloorLimitReachedWarning>
          Maximum 150 floors reached
        </FloorLimitReachedWarning>
      )}

      {upperFloors?.length > 0 && (
        <MultiLineStyled>{upperFloors.map(getLine)}</MultiLineStyled>
      )}

      <MultiLineHeader columns={columns}>
        {FORM_BUILDER_COPY.groundTitle}
      </MultiLineHeader>

      {!hasUpperRow && (
        <StyledAddLineButton
          isChild
          type="button"
          onClick={() => onInsertRows(1, LINE_PARAMS.UPPER)}
        >
          <StyledAddIcon />
          {FORM_BUILDER_COPY.multiline.buttons.addGroundFloorLineAbove}
        </StyledAddLineButton>
      )}
      {groundFloors?.length > 0 && (
        <MultiLineStyled>
          {groundFloors.map((floor: any, i: number) =>
            getLine(floor, i + upperFloors.length),
          )}
        </MultiLineStyled>
      )}

      {!hasLowerRow && (
        <StyledAddLineButton
          type="button"
          isChild
          onClick={() => onInsertRows(1, LINE_PARAMS.LOWER)}
        >
          <StyledAddIcon />
          {FORM_BUILDER_COPY.multiline.buttons.addGroundFloorLineBelow}
        </StyledAddLineButton>
      )}

      {lowerFloors?.length > 0 && (
        <MultiLineStyled>
          {lowerFloors.map((floor: any, i: number) =>
            getLine(floor, i + (upperFloors.length + groundFloors.length)),
          )}
        </MultiLineStyled>
      )}

      {canAddLowerFloor ? (
        <StyledAddLineButton
          type="button"
          onClick={() => onInsertRows(1, LINE_PARAMS.BELOW)}
        >
          <StyledAddIcon />
          {buildingType === USE_TYPES.OFFICE
            ? FORM_BUILDER_COPY.multiline.buttons.addLineBelow
            : FORM_BUILDER_COPY.multiline.buttons.addLineBelowRetailIndustrial}
        </StyledAddLineButton>
      ) : (
        <FloorLimitReachedWarning>
          Maximum -10 floors reached
        </FloorLimitReachedWarning>
      )}
      <BatchControlOrdered
        addBatchLabel={
          buildingType === USE_TYPES.OFFICE
            ? addBatchLabel
            : FORM_BUILDER_COPY.multiline.batch.labelRetailIndustrial
        }
        addButton={addButton}
        onInsertRows={onInsertRows}
        maxBottomValue={lowestValidFloorNumber}
        maxTopValue={highestValidFloorNumber}
        currentHighestTopValue={currentHighestFloor}
        currentHighestBottomValue={currentLowestFloor}
      />
    </div>
  );
};

export default FloorPartitionMultiline;
